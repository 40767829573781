import { Link } from "react-router-dom"
import { FooterNav, MENU } from "./header"



export const SiteFooter: React.FC = () => {
    return (
        <footer className="site-footer-wrapper">
            <div className="content-wrapper site-footer">
                <Link to="/">
                    <img src="/img/logo.svg" alt="logo" className="footer-logo" />
                </Link>
                <FooterNav menu={MENU} />
                <div className="footer-contacts">
                    <div className="messanger-contact-wrapper">
                        <img src="/img/tg.svg" alt="telegram" width={32} height={32} />
                        <a
                            style={{textDecoration: "none"}}
                            href="https://t.me/thai_properties"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-label"
                        >@thai_properties</a>
                    </div>
                    <div className="messanger-contact-wrapper">
                        <img src="/img/wp.svg" alt="telegram" width={32} height={32} />
                        <a
                            style={{textDecoration: "none"}}
                            href="https://wa.me/message/6GKKRLYY44RDJ1"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-label"
                        >+65 66 015 9388</a>
                    </div>
                </div>
            </div>
            <div className="privacy-link-wrapper">
                <Link className="privacy-link" to="/privacy">Политика конфиденциальности</Link>
            </div>
        </footer>
    )
}